<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h4>
                  <router-link :to="{name:'user'}">
                    <i class="fas fa-arrow-left mr-2"></i>
                  </router-link>
                  {{ user.full_name }}'s Role
                </h4>
              </div>
              <!--                        <div academic-class="col-2 ">-->
              <!--                            <router-link :to="{name:'dashboard'}" academic-class="btn btn-primary">-->
              <!--                                <i academic-class="fas fa-arrow-left"></i>-->
              <!--                                Back-->
              <!--                            </router-link>-->
              <!--                        </div>-->
              <!--                        <div academic-class="col-4">-->
              <!--                            <h4>Manage Slider Image</h4>-->
              <!--                        </div>-->
              <div class="col-6 text-right">
                <!--                <button @click="showModal" academic-class="btn btn-primary ml-2">-->
                <!--                 -->
                <!--                  Manage User's Role-->
                <!--                </button>-->
              </div>
              <div class="col-12">
                <table class="table">
                  <thead class="text-left">
                  <th>Roles</th>
                  <th></th>
                  </thead>
                  <tbody class="text-left">
                  <tr>
                    <th>
                      <b-form-checkbox
                          id="checkbox-1"
                          v-model="status"
                          @change="toggleUsersRole()"
                          name="checkbox-1"
                      > Select All
                      </b-form-checkbox>
                    </th>
                    <th>Given</th>
                  </tr>
                  <tr v-for="(role, i) of roles" :key="i">
                    <th>
                      <router-link v-if="role.name !='admin'" :to="{name:'role-permission', params:{id:role.id}}">
                        {{ role.name }}
                      </router-link>
                      <span v-else>{{ role.name }}</span>
                    </th>
                    <td>
                      <b-form-checkbox @change="assignRole()" switch size="lg" v-model="selected_roles"
                                       :disabled="is_admin && role.name =='admin'"
                                       :value="role.name"></b-form-checkbox>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="modal-scrollable" size="xl" ref="my-modal" hide-footer title="Faculty">
        <div class="d-block text-left"></div>
        <div class="row">
          <div class="col-8">
            <label for="faculty">Roles</label>
            <v-select
                v-model="selected_roles"
                :items="roles"
                item-value="name"
                item-text="name"
                chips
                dense
                outlined
                multiple
                placeholder="Role"
            ></v-select>
            <!-- <b-input id="faculty" v-model="faculty.title"></b-input> -->
            <!-- <span academic-class="text-danger" v-if="$v.faculty.title.$error">Title is required</span> -->
          </div>

          <div class="col-4 text-right mt-5">
            <b-button size="sm" class variant="danger" @click="hideModal">Cancel</b-button>
            <b-button size="sm" class="ml-2" variant="success" @click="assignRole">Save</b-button>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- <pre>{{user_roles}}</pre> -->
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import RoleService from "@/services/user/role/RoleService";

const userService = new UserService();
const roleService = new RoleService();

export default {
  name: "users",
  components: {},
  data() {
    return {
      selected_roles: [],
      user_roles: [],
      roles: [],
      user: {},
      total: null,
      status: false,
      perPage: null,
      total_role: 0,
      is_admin: false
    };
  },
  computed: {
    user_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.userRoles();
    this.getUser();
  },
  methods: {
    userRoles() {
      userService.userRoles(this.user_id).then(response => {
        this.user_roles = response.data;
        this.selected_roles = []
        if (this.user_roles && this.user_roles.length > 0) {
          this.user_roles.forEach(element => {
            this.selected_roles.push(element)
          });
        }
        this.getRoles();
      });
    },
    getUser() {
      userService.show(this.user_id).then(response => {
        this.user = response.data.user;
        if (this.user.type && this.user.type.includes('admin')) {
          this.is_admin = true
        }
      });
    },
    getRoles() {
      roleService.all(false).then(response => {
        this.roles = response.data.data;
        this.total_role = this.roles.length
        if (this.total_role == this.selected_roles.length) {
          this.status = true;
        }
      });
    },
    showModal(id = null) {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.teacher = [];
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    }, toggleUsersRole() {
      if (!this.status) {
        this.selected_roles = [];
      } else {
        roleService.all(false).then(response => {
          let roles = response.data.data;
          if (roles && roles.length > 0) {
            roles.forEach(ele => {
              this.selected_roles.push(ele.name)
            })
          }
          this.assignRole()
        });
      }
    },

    deleteUserRole(role) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
                .removeRole(this.user_id, {roles: [role]})
                .then(response => {
                  this.$snotify.success("Deleted successfully");
                  this.assignRole()
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    }, assignRole() {
      userService.assignRole(this.user_id, {roles: this.selected_roles}).then(response => {
        this.user_roles = [];
        this.$snotify.success("Role assigned to user")
        this.userRoles()
      })
    }
  }
};
</script>
